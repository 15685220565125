import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet'

import ContentfulPageHeader from "../sections/ContentfulPageHeader"
import ContentfulMainIntro from "../sections/ContentfulMainIntro"
import ContentfulTextWithImageOnTheRight from "../sections/ContentfulTextWithImageOnTheRight"
import ContentfulTextWithMultipleImages from "../sections/ContentfulTextWithMultipleImages"
import ContentfulImageList from "../sections/ContentfulImageList"
import ContentfulNewsAndInsights from "../sections/ContentfulNewsAndInsights"
import ContentfulCta from "../sections/ContentfulCta"
import ContentfulTextWithImageOnTheLeft from "../sections/ContentfulTextWithImageOnTheLeft"
import ContentfulTextWithImageOnTheLeftSimple from "../sections/ContentfulTextWithImageOnTheLeftSimple"
import ContentfulTeamMembers from "../sections/ContentfulTeamMembers"
import ContentfulFeaturedText from "../sections/ContentfulFeaturedText"
import ContentfulRichContent from "../sections/ContentfulRichContent"
import ContentfulDivider from "../sections/ContentfulDivider"

const PageTemplate = ({ data }) => {
  const page = data.contentfulPage
  const seoTitle = page.title
  const seoDescription = page.metaDescription.metaDescription
  const sections = page.sections


  return (
    <Layout key={page.id}>
      <SEO title={seoTitle} />
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>
      <article>
        { sections.map((section) => {

          const sectionType = section.__typename;
          return (
            <div key={`div${section.id}`}>
              {sectionType === 'ContentfulPageHeader' && <ContentfulPageHeader type={sectionType} section={section} key={section.id}/>}
              {sectionType === 'ContentfulMainIntro' && <ContentfulMainIntro type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulTextWithImageOnTheRight' && <ContentfulTextWithImageOnTheRight type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulTextWithMultipleImages' && <ContentfulTextWithMultipleImages type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulImageList' && <ContentfulImageList type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulNewsAndInsights' && <ContentfulNewsAndInsights type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulCta' && <ContentfulCta type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulTextWithImageOnTheLeft' && <ContentfulTextWithImageOnTheLeft type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulTextWithImageOnTheLeftSimple' && <ContentfulTextWithImageOnTheLeftSimple type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulTeamMembers' && <ContentfulTeamMembers type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulFeaturedText' && <ContentfulFeaturedText type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulRichContent' && <ContentfulRichContent type={sectionType} section={section} key={section.id} />}
              {sectionType === 'ContentfulDivider' && <ContentfulDivider type={sectionType} section={section} key={section.id} />}
            </div>
          )
        })}
      </article>
      
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: {eq: $slug}) {
      slug
      title
      metaDescription {
        metaDescription
      }
      sections {
        __typename
        ... on Node {
          ... on ContentfulPageHeader {
            id
            heading
            subText
            backgroundImage {
              description
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
             }
            }
            fullHeight
          }
          ... on ContentfulMainIntro {
            id
            heading
            text
            buttonText
            buttonSlug
          }
          ... on ContentfulTextWithImageOnTheRight {
            id
            heading
            buttonText
            buttonSlug
            longText {
              longText
            }
            image {
              title
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulTextWithMultipleImages {
            id
            title
            smallTitle
            text
            buttonText
            buttonSlug
            image1 {
              title
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            image2 {
              title
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            image3 {
              title
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulImageList {
            id
            title
            description
            buttonText
            buttonSlug
            images {
              id
              file {
                fileName
                url
              }
            }
          }
          ... on ContentfulNewsAndInsights {
            id
            title
            smallTitle
            insights {
              id
              slug
              title
              label
              featuredImage {
                file {
                  fileName
                  url
                }
              }
            }
            news {
              id
              label
              title
              slug
            }
            featuredArticle {
              id
              slug
              label
              title
            }
          }
          ... on ContentfulCta {
            id
            text
            buttonText
          }
          ... on ContentfulTextWithImageOnTheLeft {
            id
            smallTitle
            title
            text
            image {
              file {
                url
                fileName
              }
            }
            listItems
            listTitle
            buttonText
            buttonSlug
          }
          ... on ContentfulTextWithImageOnTheLeftSimple {
            image {
              file {
                fileName
                url
              }
            }
            title
            smallTitle
            richText {
              json
            }
          }
          ... on ContentfulTeamMembers {
            id
            title
            people {
              image {
                file {
                  fileName
                  url
                }
              }
              name
              position
              linkedIn
            }
          }
          ... on ContentfulFeaturedText {
            id
            title
            text
          }
          ... on ContentfulRichContent {
            id
            title
            childContentfulRichContentContentRichTextNode {
              json
            }
          }
          ... on ContentfulDivider {
            id
          }
        }
      }
    }
  }
`

export default PageTemplate
