import React from "react"

const ContentfulNewsAndInsights = ({ section }) => (
	<div>
		<section className="o-cw-insights o-cw-insights--dark o-cw-insights--wcl">
			<div className="container">
				<div className="o-cw-insights__row row">
					<span className="o-cw-insights__shape h-abs-center-bottom"></span>
					<header className="col-12 col-sm-7 offset-sm-3 order-1 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<h2 className="a-si h-text-bold">
								<a href="/insights" title="what we're thinking" className="a-link--wline" target="">
									{section.smallTitle}
								</a>
							</h2>
						</div>
						<div className="scroll-animation scroll-animation__ease-in-from-bottom active">
							<h3 className="a-title-40 ">
								{section.title}
							</h3>
						</div>
					</header>
					<div className="o-cw-insights__header col-12 col-sm-7 offset-sm-3 col-md-12 offset-md-0 col-lg-3 col-xl-3 order-3 order-lg-2">
						<div className="o-cw-insights__header-left scroll-animation scroll-animation__children-ease-in-from-bottom active">
							{section.news.map((news) => (
								<a key={news.id} href={`/${news.slug}`} title=" Yemora expands into Montréal and secures $500k grant from Government of Québec to support hiring and training initiatives" className="o-cw-insights__item-left">
									<p className="m-card__term h-text-medium">{news.label}</p>
									<div className="m-card__title-content">
										<span>{news.title}</span>
									</div>
								</a>
							))}
						</div>
					</div>
					<div className="o-cw-insights__items col-12 col-12 order-2 col-sm-7 offset-sm-3 col-md-12 offset-md-0 col-lg-7 offset-lg-1 order-lg-3 col-xl-8 offset-xl-1 scroll-animation scroll-animation__list-ease-in-from-bottom active">
						<div className="row">
							<div className="o-cw-insights__items--wcl-col-cards col-12">
							{section.insights.map((insight) => (
								<div className="o-cw-insights__item" key={insight.id}>
									<a href={`/${insight.slug}`} title="Future of digital study" className="m-card m-card--w-image m-card--landscape m-card--wide">
										<p className="m-card__term h-text-medium">{insight.label}</p>
										<div className="m-card__content m-card__content--no-term">
											<picture>
												<img className="m-card__bg b-lazy h-abs-bg b-loaded" alt={insight.featuredImage.fileName} src={insight.featuredImage.file.url}/>
											</picture>
											<div className="m-card__title">
												<div className="m-card__title-content">
													<span>{insight.title}</span>
												</div>
											</div>
										</div>
									</a>
								</div>
							))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="o-cw-insights__news">
				<div className="container">
					<div className="row">
						<div className="o-cw-insights__news-col col-12 offset-sm-3 col-sm-7 offset-md-0 col-md-12 offset-lg-4 col-lg-7">
							<div className="o-cw-insights__news-content">
								<div className="row scroll-animation scroll-animation__ease-in-from-bottom active">
									<div className="col-12 col-lg-8">
										<p className="o-cw-insights__news-term m-card__term h-text-medium">{section.featuredArticle.label}</p>
										<p className="o-cw-insights__news-title m-card__title-content h-text-medium">{section.featuredArticle.title}</p>
									</div>
									<div className="col-12 col-lg-4">
										<a href={`/${section.featuredArticle.slug}`} target="_self" title="learn more" className="a-link h-link o-cw-insights__news-link">learn more</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
)

export default ContentfulNewsAndInsights
